/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  // left: 0;
  // right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: black;
}
#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #d8f000;
  border-top-color: #6210a2;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}
@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#preloader1 {
  width: 48px;
  height: 48px;
  top: 50vh;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
#preloader1::before,
#preloader1::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #fff;
  animation: prixClipFix 2s linear infinite;
}
#preloader1::after {
  border-color: #ff3d00;
  animation: prixClipFix 2s linear infinite, rotate 0.5s linear infinite reverse;
  inset: 6px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

#preloader2 {
  width: 60px;
  height: 60px;
  top: 50vh;
  border: 2px solid #fff;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
#preloader2::after,
#preloader2::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  background: #ff3d00;
  width: 6px;
  height: 6px;
  transform: translate(150%, 150%);
  border-radius: 50%;
}
#preloader2::before {
  left: auto;
  top: auto;
  right: 0;
  bottom: 0;
  transform: translate(-150%, -150%);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.auth-loader {
  position: absolute;
  width: 20vh;
  height: 20vh;
  top: 50%;
  left: 50%;
  margin-top: -10vh;
  margin-left: -10vh;
  perspective: 60vh;
}
.auth-loader:before,
.auth-loader:after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.auth-loader:before {
  left: -13.333333333333334vh;
  background: url(../../../img/simbalotto-logo.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateZ(0vh);
  z-index: 1;
  -webkit-animation: rotation1 1.5s ease-out infinite;
  animation: rotation1 1.5s ease-out infinite;
}
.auth-loader:after {
  right: -13.333333333333334vh;
  background: url(../../../img/simbalotto-logo.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateZ(0vh);
  z-index: 1;
  -webkit-animation: rotation2 1.5s ease-out infinite;
  animation: rotation2 1.5s ease-out infinite;
}
@-webkit-keyframes rotation1 {
  25% {
    left: 0;
    transform: translateZ(-10vh);
  }
  50% {
    left: 13.333333333333334vh;
    transform: translateZ(0vh);
  }
  75% {
    left: 0;
    transform: translateZ(20vh);
    z-index: 2;
  }
}
@keyframes rotation1 {
  25% {
    left: 0;
    transform: translateZ(-10vh);
  }
  50% {
    left: 13.333333333333334vh;
    transform: translateZ(0vh);
  }
  75% {
    left: 0;
    transform: translateZ(20vh);
    z-index: 2;
  }
}
@-webkit-keyframes rotation2 {
  25% {
    right: 0;
    transform: translateZ(20vh);
    z-index: 2;
  }
  50% {
    right: 13.333333333333334vh;
    transform: translateZ(0vh);
  }
  75% {
    right: 0;
    transform: translateZ(-10vh);
  }
}
@keyframes rotation2 {
  25% {
    right: 0;
    transform: translateZ(20vh);
    z-index: 2;
  }
  50% {
    right: 13.333333333333334vh;
    transform: translateZ(0vh);
  }
  75% {
    right: 0;
    transform: translateZ(-10vh);
  }
}
